<template>
  <base-section id="certificacion">
    <v-container class="mb-n15">
      <v-row>
        <v-col cols="12">
          <base-section-heading title="EDUCACIÓN MÉDICA CONTINUA">
          </base-section-heading>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <!--<base-title
            class="red--text"
            title="Bienvenidos a la sección de Educación Médica Continua."
            tag="div"
            style="color: red"
          />-->

          <h2 class="font-weight-bold mb-0 red--text text-left mb-4" >
            Bienvenidos a la sección de Educación Médica Continua.
          </h2>

          <div>
            <p style="text-align: justify">
              Este Comité tiene como misión esencial el coadyuvar en la labor conjunta del Consejo Mexicano de Patología Clínica y Medicina de Laboratorio en todas aquellas actividades académicas que favorezcan la actualización de todos aquellos miembros en los diferentes temas de la especialidad.
            </p>

            <p style="text-align: justify">
              El comité de Educación Continua del Consejo Mexicano de Patología Clínica y Medicina de Laboratorio tiene como objeto definir los criterios para la evaluación de las actividades de Educación Continua con fines de Recertificación de los Patólogos Clínicos.
            </p>
            <p style="text-align: justify">
              Las funciones propias de este Comité son las siguientes:
            </p>

            <p style="text-align: justify">
              4.1 Establecer las categorías de las actividades de enseñanza aprendizaje que serán acreditadas por el Consejo.
            </p>

            <p style="text-align: justify">
              4.2 Definir las instituciones que serán reconocidas como idóneas para actividades de educación continua y las categorías de las mismas.
            </p>

            <p style="text-align: justify">
              4.3 Establecer el tabulador para la asignación de la puntuación y obtención de créditos por actividades de educación continua.
            </p>
            <p style="text-align: justify">
              4.4 Otorgar el aval de educación continua a las Instituciones Oferentes de Educación Continua que lo requieran y soliciten de acuerdo al Reglamento del Consejo Mexicano de Patología clínica y Medicina de Laboratorio así como al manual de procedimientos propio de este Comité.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionInfoEducacionContinua',

    data: () => ({

    }),
  }
</script>
